<template>
    <v-container>
        <!-- <v-row>
            <v-col>
                <v-alert dense :type="alertType">
                    {{ alertMessage }}
                </v-alert>
            </v-col>
        </v-row> -->

        <v-row>
            <v-col cols="12">
                <v-radio-group
                    row
                    v-model="sospeso"
                    :disabled="readOnly"
                    :class="{ changed: isPropertyChanged('Sospesa') }"
                    >
                    <v-radio label="Attiva" value="0"></v-radio>
                    <v-radio label="Sospesa" value="1"></v-radio>
                </v-radio-group>

            </v-col>
            <v-col cols="12" sm="5" md="4" lg="4">
                <v-card class="promoImage d-flex flex-column">
                    <v-row
                        :class="{ changed: isPropertyChanged('ObjectIdImage') }"
                    >
                        <v-col>
                            <img
                                class="promoImg"
                                v-if="hasImage"
                                :src="getImageFile"
                            />

                        </v-col>
                    </v-row>
                    <v-spacer></v-spacer>
                    <v-card-actions>
                        <v-file-input
                            :label="btnImage"
                            show-size
                            accept=".png, .jpg, .jpeg"
                            small-chips
                            :rules="rules"
                            v-model="currentPromo.imageFile"
                            prepend-icon="mdi-camera"
                            :disabled="readOnly"
                        >
                        </v-file-input>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12" sm="7" md="8" lg="8">
                <!-- <v-switch
                    v-model="currentPromo.Sospeso"
                    :label="currentPromo.Sospeso ? 'Attiva' : 'Sospendi'"
                ></v-switch> -->


                <!-- <v-select
                    :items="items"
                    item-text="desc"
                    item-value="id"
                    label="Categoria"
                    outlined
                    v-model="currentPromo.Type"
                    :disabled="readOnly"
                    :class="{ changed: isPropertyChanged('Type') }"
                >
                </v-select> -->

                <PromoPrioritySelector
                    :model.sync="currentPromo.Priorita"
                    :class="{ changed: isPropertyChanged('Priorita') }"
                    :disabled="readOnly"
                ></PromoPrioritySelector>

                <PromoCategorySelector
                    :farmId="$route.params.id"
                    :model.sync="currentPromo.Type"
                    :class="{ changed: isPropertyChanged('Type') }"
                    :disabled="readOnly"
                ></PromoCategorySelector>

                <v-text-field
                    outlined
                    v-model="currentPromo.Titolo"
                    label="Titolo"
                    :disabled="readOnly"
                    :class="{ changed: isPropertyChanged('Titolo') }"
                    counter
                >
                </v-text-field>

                <v-textarea
                    outlined
                    v-model="currentPromo.Descrizione"
                    label="Testo Promo"
                    counter
                    rows="11"
                    :disabled="readOnly"
                    :class="{ changed: isPropertyChanged('Descrizione') }"
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row>

            <v-col cols="12">
                <v-switch
                    v-model="publishDateCustom"
                    :label="publishDateCustom ? 'Personalizza Pubblicazione' : 'Personalizza Pubblicazione'"
                    :disabled="readOnly"
                ></v-switch>
            </v-col>

            <v-col
                cols="6"
                :class="{ changed: isPropertyChanged('InizioValidita') }"
            >
                <DateSelector
                    :formDates.sync="inizioValidita"
                    :label="'Inizio Validità'"
                    :disabled="readOnly"
                ></DateSelector>
            </v-col>

            <v-col
                cols="6"
                :class="{ changed: isPropertyChanged('FineValidita') }"
            >
                <DateSelector
                    :formDates.sync="fineValidita"
                    :label="'Fine Validità'"
                    :disabled="readOnly"
                ></DateSelector>
            </v-col>

            <v-col
                cols="6"
                :class="{ changed: isPropertyChanged('InizioPubblicazione') }"
            >
                <DateSelector
                    :formDates.sync="inizioPubblicazione"
                    :label="'Inizio Pubblicazione'"
                    :disabled="readOnly || !publishDateCustom"
                ></DateSelector>
            </v-col>

            <v-col
                cols="6"
                :class="{ changed: isPropertyChanged('FinePubblicazione') }"
            >
                <DateSelector
                    :formDates.sync="finePubblicazione"
                    :label="'Fine Pubblicazione'"
                    :disabled="readOnly || !publishDateCustom"
                ></DateSelector>
            </v-col>

            <v-col
                cols="12"
                sm="12"
                md="6"
                :class="{ changed: isPropertyChanged('ObjectIdAudio') }"
            >
                <v-row class="align-center px-4">
                    <v-file-input
                        :label="btnAudio"
                        show-size
                        prepend-icon="mdi-music"
                        accept="audio/mpeg"
                        small-chips
                        :rules="rules"
                        v-model="currentPromo.audioFile"
                        :disabled="readOnly"
                    ></v-file-input>
                    <v-btn
                        class="ml-4"
                        :disabled="!hasAudio"
                        @click.stop="showMultimediaContent(0)"
                        ><v-icon left dark> mdi-play </v-icon>Play</v-btn
                    >
                </v-row>
            </v-col>

            <v-col
                cols="12"
                sm="12"
                md="6"
                :class="{ changed: isPropertyChanged('ObjectIdVideo') }"
            >
                <v-row class="align-center px-4">
                    <v-file-input
                        :label="btnVideo"
                        show-size
                        prepend-icon="mdi-video"
                        accept="video/mp4"
                        small-chips
                        :rules="rules"
                        v-model="currentPromo.videoFile"
                        :disabled="readOnly"
                    ></v-file-input>

                    <v-btn
                        class="ml-4"
                        :disabled="!hasVideo"
                        @click.stop="showMultimediaContent(1)"
                        ><v-icon left dark> mdi-play </v-icon>Play</v-btn
                    >
                </v-row>
            </v-col>

            <v-col
                cols="12"
                sm="12"
                md="6"
                :class="{ changed: isPropertyChanged('ObjectIdAttachment') }"
            >
                <v-row class="align-center px-4">
                    <v-file-input
                        :label="btnAttachment"
                        show-size
                        accept=".png, .jpg, .jpeg, .pdf"
                        small-chips
                        :rules="rules"
                        v-model="currentPromo.attachFile"
                        :disabled="readOnly"
                    >
                    </v-file-input>
                    <v-btn
                        class="ml-4"
                        :disabled="!hasAttachment"
                        @click.stop="showMultimediaContent(2)"
                    >
                        <v-icon left dark> mdi-eye </v-icon>
                        Visualizza
                    </v-btn>
                    <v-btn
                        class="ml-4"
                        :disabled="!hasAttachment || readOnly"
                        color="error"
                        @click.stop="removeAttachment"
                        icon
                    >
                        <v-icon left> mdi-delete </v-icon>
                    </v-btn>
                </v-row>
            </v-col>

            <v-col cols="12">
                <StoresTable
                    :stores.sync="currentPromo.PuntiVendita"
                    :readonly="readOnly"
                    :changed="isPropertyChanged('PuntiVendita')"
                ></StoresTable>
            </v-col>
        </v-row>

        <ShowContent
            v-if="showContent"
            @close="showContent = !showContent"
            :media="mediaObject"
        ></ShowContent>
    </v-container>
</template>

<script>
import { mapActions } from "vuex";
export const DateSelector = () => import("../core/DateSelector");
export const ShowContent = () => import("../core/ShowContent");
export const StoresTable = () => import("./PromoStores");
export const PromoCategorySelector = () =>
    import("../core/PromoCategorySelector");
export const PromoPrioritySelector = () =>
    import("../core/PromoPrioritySelector");

import { checkObjectDiff, getKeysDiff } from "../../utils/utils";

import * as promoActions from "../../store/actions/promo";

export default {
    props: {
        promo: {
            type: Object,
            requirted: true
        },
        previous: {
            type: Object,
            required: false,
            default: null
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false
        },
        fakesync: {
            type: Number,
            required: false,
            default: 0
        }
    },

    data: () => ({
        loaded: false,
        publishDateCustom: false,
        modifiy: false,
        showContent: false,
        mediaObject: {},
        // items: [
        //     { id: 0, desc: "Offerta" },
        //     { id: 1, desc: "Attività generica" },
        //     { id: 2, desc: "Coop Italia" },
        //     { id: 3, desc: "Istituzionale" },
        //     { id: 4, desc: "Punto Vendita" }
        // ],
        currentPromo: null,
        resetModel: null,
        isChanged: false,
        //////
        rules: [
            value =>
                !value ||
                value.size < 50000000 ||
                "La dimensione deve essere inferiore a 50 MB!"
        ],
        ////
        propertiesChanged: [],
        typeHover: false
    }),

    watch: {
        publishDateCustom: {
            handler: function(val) {
                if (val === false && this.loaded) {
                    this.currentPromo.InizioPubblicazione = this.currentPromo.InizioValidita;
                    this.currentPromo.finePubblicazione = this.currentPromo.FineValidita;
                }
            },
            deep: true,
            immediate: true
        },
        currentPromo: {
            handler: function(val) {
                this.isChanged = checkObjectDiff(
                    this.currentPromo,
                    this.resetModel
                );
                this.$emit("update:promo", this.currentPromo);
            },
            deep: true,
            immediate: true
        },
        fakesync: {
            handler: function(val, oldval) {
                console.log("modifica da parent", val, oldval, this.promo);
                if (oldval !== undefined) {
                    this.resetModel = JSON.parse(JSON.stringify(this.promo));
                    this.currentPromo = JSON.parse(JSON.stringify(this.promo));
                }
                // if (this.readOnly === true) {
                //     const diffs = getKeysDiff(this.promo, this.previous);
                //     this.propertiesChanged = diffs.map(diff => {
                //         return diff.path[0];
                //     });
                // }
            },
            deep: true,
            immediate: true
        }
    },

    components: {
        DateSelector,
        ShowContent,
        StoresTable,
        PromoCategorySelector,
        PromoPrioritySelector
    },

    computed: {
        filePath() {
            return "/files/fileidstream";
        },

        sospeso: {
            get: function() {
                return this.currentPromo.Sospeso === true ? "1" : "0";
            },
            set: function(newValue) {
                console.log(newValue, newValue === "1");
                this.currentPromo.Sospeso = newValue === "1" ? true : false;
                console.log(this.currentPromo.Sospeso);
            }
        },

        btnAudio() {
            return this.hasAudio ? "Sostituisci Audio" : "Carica Audio";
        },

        btnVideo() {
            return this.hasVideo ? "Sostituisci Video" : "Carica Video";
        },

        btnAttachment() {
            return this.hasAttachment
                ? "Sostituisci Allegato"
                : "Carica Allegato";
        },

        btnImage() {
            return this.hasImage ? "Sostituisci Immagine" : "Carica Immagine";
        },

        hasVideo() {
            return (
                this.currentPromo.VideoName !== null &&
                this.currentPromo.ObjectIdVideo.length > 0
            );
        },

        hasAudio() {
            return (
                this.currentPromo.AudioName !== null &&
                this.currentPromo.ObjectIdAudio.length > 0
            );
        },

        hasImage() {
            return (
                this.currentPromo.ImageName !== null &&
                this.currentPromo.ObjectIdImage.length > 0
            );
        },

        hasAttachment() {
            return (
                this.currentPromo.AttachmentName !== null &&
                this.currentPromo.ObjectIdAttachment.length > 0
            );
        },

        getAudioFile() {
            return (
                this.filePath +
                "/icoop_audio/" +
                this.currentPromo.ObjectIdAudio
            );
        },

        getImageFile() {
            return (
                this.filePath +
                "/icoop_image/" +
                this.currentPromo.ObjectIdImage
            );
        },

        getVideoFile() {
            return (
                this.filePath +
                "/icoop_video/" +
                this.currentPromo.ObjectIdVideo
            );
        },

        getAttachmentFile() {
            return (
                this.filePath +
                "/icoop_attachment/" +
                this.currentPromo.ObjectIdAttachment
            );
        },

        inizioValidita: {
            get() {
                return this.currentPromo.InizioValidita.substring(0, 10);
            },
            set(newValue) {
                this.currentPromo.InizioValidita = `${newValue}T00:00:00Z`;
                if (this.publishDateCustom === false) {
                    this.currentPromo.InizioPubblicazione = this.currentPromo.InizioValidita;
                }
            }
        },

        fineValidita: {
            get() {
                return this.currentPromo.FineValidita.substring(0, 10);
            },
            set(newValue) {
                this.currentPromo.FineValidita = `${newValue}T00:00:00Z`;
                if (this.publishDateCustom === false) {
                    this.currentPromo.FinePubblicazione = this.currentPromo.FineValidita;
                }
            }
        },

        inizioPubblicazione: {
            get() {
                if (this.currentPromo.InizioPubblicazione === null) {
                    return this.currentPromo.InizioValidita.substring(0, 10);
                }
                return this.currentPromo.InizioPubblicazione.substring(0, 10);
            },
            set(newValue) {
                this.currentPromo.InizioPubblicazione = `${newValue}T00:00:00Z`;
            }
        },

        finePubblicazione: {
            get() {
                if (this.currentPromo.FinePubblicazione === null) {
                    return this.currentPromo.FineValidita.substring(0, 10);
                }
                return this.currentPromo.FinePubblicazione.substring(0, 10);
            },
            set(newValue) {
                this.currentPromo.FinePubblicazione = `${newValue}T00:00:00Z`;
            }
        },
        alertType() {
            const group =
                this.currentPromo.PuntiVendita.length > 1 ? true : false;

            if (group) {
                return "warning";
            }
            return "success";
        },

        alertMessage() {
            const group =
                this.currentPromo.PuntiVendita.length > 1 ? true : false;

            if (group) {
                return "Attenzione, programmata di Gruppo";
            }
            return "Programmata solo per questo PV";
        }
    },

    methods: {
        ...mapActions({
            updatePromo: promoActions.PROMO_UPDATEPROMO,
            // approvePromo: promoActions.PROMO_APPROVEPROMO,
            rejectPromo: promoActions.PROMO_REJECTPROMO
        }),

        isPropertyChanged(property) {
            if (!this.readOnly) {
                return false;
            }
            return this.propertiesChanged.includes(property);
        },

        showMultimediaContent(type) {
            switch (type) {
                case 0:
                    this.mediaObject = {
                        type: type,
                        src: this.getAudioFile,
                        name: this.currentPromo.AudioName,
                        poster: {
                            src: this.hasAttachment
                                ? this.getAttachmentFile
                                : this.getImageFile,
                            name: this.hasAttachment
                                ? this.currentPromo.AttachmentName
                                : this.currentPromo.ImageName
                        }
                    };
                    break;

                case 1:
                    this.mediaObject = {
                        type: type,
                        src: this.getVideoFile,
                        name: this.currentPromo.VideoName,
                        poster: null
                    };
                    break;

                case 2:
                    this.mediaObject = {
                        type: type,
                        src: this.getAttachmentFile,
                        name: this.currentPromo.AttachmentName
                    };
                    break;
            }
            this.showContent = true;
        },

        removeAttachment() {
            this.$confirmdialog
                .open({
                    type: "Warning",
                    title: "Rimuovi allegato",
                    message: `Confermi la rimozione dell'allegato?`,
                    color: "primary"
                })
                .then(async response => {
                    this.currentPromo.ObjectIdAttachment = "";
                    this.currentPromo.AttachmentName = "";
                })
                .catch(err => {
                    console.log("Annullato", err);
                })
                .finally(() => {
                    this.$confirmdialog.destroy();
                });
        }
    },

    beforeDestroy() {},

    beforeMount() {
        if (this.promo.Priorita === null) {
            this.promo.Priorita = 10;
        }
        if (this.promo.InizioPubblicazione === null && this.promo.FinePubblicazione === null) {
            this.promo.InizioPubblicazione = this.promo.InizioValidita;
            this.promo.FinePubblicazione = this.promo.FineValidita;
        }
        else {
            if (this.promo.InizioPubblicazione !== this.promo.InizioValidita || this.promo.FinePubblicazione !== this.promo.FineValidita) {
                this.publishDateCustom = true;
            }
        }
        this.resetModel = JSON.parse(JSON.stringify(this.promo));
        this.currentPromo = JSON.parse(JSON.stringify(this.promo));
        console.log(this.currentPromo.PuntiVendita);

        const diffs = getKeysDiff(this.promo, this.previous);
        console.log(diffs);
        console.log(this.promo);

        this.propertiesChanged = diffs.map(diff => {
            return diff.path[0];
        });
    },

    mounted() {
        this.$nextTick(() => {
            this.loaded = true;
        })
    }
};
</script>

<style scoped>
.promoImage {
    height: 575px;
    /*border-style: dotted;
    border-color: green;
    border-width: 4px;*/
}

.promoImg {
    width: 100%;
    object-fit: contain;
    padding: 0;
    margin: 0;
    height: 395px;
}

.changed >>> .v-input__slot {
    background: rgba(255, 0, 0, 0.1);
}
</style>
